export const stylesFilledEthicalGuidelinesForm = `
.agentFormContainer {
    color: black;
    display: flex;
    flex-direction: column;
    font-family: Calibri;
    margin: 12rem 4rem 0 4rem;
}

.bg-image {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
}

.bg-image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.formBody {
    margin-top: 2rem;
}

.formContent {
    font-size: 0.7rem;
    
}

.formContent h1 {
    font-size: 1rem;
    text-align: center;
}

.formContent h2 {
    font-size: 0.7rem;
}

.topics {
    font-weight: bold;
}

.agentSignatureLabel {
    font-family: 'Yellowtail', sans-serif;
    border: none;
    background: #f2f2f2;
    border-bottom: 1px solid black;
    min-width: 7rem;
    padding: 0;
    height: 1.875rem;
    font-size: 0.85rem;
}


.agentSignatureContainer {
    font-size: 0.7rem;
    display: flex;
    flex-direction: column;
    gap: 0.6rem;
}

`