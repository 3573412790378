import './signedFormStyles.css';

function SignedFactSheetPage() {
    return (
        <div className='signed-container'>
            <img src="https://storage.googleapis.com/lsi-public/logo-bw.png" width={200}/>
            <h1>Thank you for completing the form. You can close this page now</h1>
        </div>
    );
  }
  
  export default SignedFactSheetPage;